/* eslint-disable sort-imports */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/services/i18n/translation.service';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  currentLang: string
  constructor(
    private translationService: TranslationService,
    private viewportScroller: ViewportScroller,
    private router: Router
  ) {
  }
  async ngOnInit() {
    this.translationService.getSelectedLanguage().then(resp => {
    })
    this.currentLang = await this.translationService.getSelectedLanguage()
  }
  selectLanguage(lang: string) {
    this.translationService.setLanguage(lang);
    localStorage.setItem("lang", lang)
  }

  navigateToForm() {
    this.router.navigate(['/']).then(() => {
      this.viewportScroller.scrollToAnchor('form');
    })
  }



}
