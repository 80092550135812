
export const environment = {
  production: false,
  apiUrl: 'https://email.armadilloamarillo.cloud',
  emailRegex: new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i),
  phoneRegex: new RegExp(/^(?:6[0-9]|7[1-9]|9[1-9])[0-9]{7}$/),
  passwordRegex: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d_~@#$%^&*+=`|{}:;¡!.¿?\"()\[\]-]{8,16}$/),
  recaptcha: {
    // siteKey: '6LeowSIpAAAAAIqjOnkwCROrErhWpINAX9J1I9gU',
    siteKey: '6LfDtHAnAAAAAH7G6mmSuHrZbaMZw26j02Nbw75j',
  },
  smtpServerClient: 'i8ekmf92l10osp3jd89m9200m3013'
};