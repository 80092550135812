<div class=" bg-transparent">
    <div class="row justify-content-between align-items-center">
        <div class="col-3">
            <a href="/">
                <img class=" pointer d-none d-md-inline ciudadania-icon web"
                    src="../../../assets/img/ciudadania-logo.svg" data-bs-toggle="offcanvas" alt="ciudadania-icon" />
                <img class="pointer d-block d-md-none ciudadania-icon" src="../../../assets/img/logo-sm.svg"
                    data-bs-toggle="offcanvas" alt="ciudadania-icon" />
            </a>
        </div>
        <div class="col-8">
            <div class="row align-items-end">
                <div class=" col-12 mb-2 justify-content-end col-md-6 text-end contact">
                    <img src="../../../assets/img/phone.svg" />
                    <p>+34 91 348 56 46</p>
                </div>
                <div class="d-flex col-12 justify-content-end justify-content-md-start col-md-6">
                    <p class="btn-contact" (click)="navigateToForm()">Contacta con nosotros</p>
                </div>
            </div>
        </div>
    </div>
</div>