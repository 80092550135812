<div class="bg-footer d-flex   ">
    <div class="container mt-5">
        <div class="row ">
            <div class="col-12 d-flex flex-column flex-md-row justify-content-between  ">
                <div class="content row align-items-start justify-content-lg-center mb-5 ">
                    <img class="ciudadania-icon" src="../../../assets/img/ciudadania-logo.svg"
                        data-bs-toggle="offcanvas" alt="ciudadania-icon" />

                </div>
                <div class="text-end contact align-items-start">
                    <div class="phone d-flex align-items-center justify-content-center justify-content-md-end">

                        <img src="../../../assets/img/phone.svg" alt="" />
                        <p class="phone">+34 91 348 56 46</p>
                    </div>
                    <p class="text-center">
                        Copyright © Armadillo Amarillo | <a href="https://www.armadilloamarillo.com/aviso-legal-2/"
                            target="_blank">Aviso legal</a> | <a href="https://www.armadilloamarillo.com/aviso-legal/"
                            target="_blank">Política de privacidad</a>
                    </p>
                </div>
            </div>
            <div class="col-12 col-md-6">
            </div>
        </div>
    </div>
</div>